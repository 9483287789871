exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-news-index-js": () => import("./../../../src/pages/en/news/index.js" /* webpackChunkName: "component---src-pages-en-news-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-research-awards-js": () => import("./../../../src/pages/research/awards.js" /* webpackChunkName: "component---src-pages-research-awards-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-en-js": () => import("./../../../src/templates/blog-post-en.js" /* webpackChunkName: "component---src-templates-blog-post-en-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-en-js": () => import("./../../../src/templates/index-page-en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-pages-en-js": () => import("./../../../src/templates/pages-en.js" /* webpackChunkName: "component---src-templates-pages-en-js" */),
  "component---src-templates-pages-en-white-js": () => import("./../../../src/templates/pages-en-white.js" /* webpackChunkName: "component---src-templates-pages-en-white-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-publications-en-js": () => import("./../../../src/templates/publications-en.js" /* webpackChunkName: "component---src-templates-publications-en-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

